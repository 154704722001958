.a-modal {
  background-image: url("https://rubookmac.com/images/how-to-turn-off-the-pop-up-blocker-in-safari-for-iphone-ipad.jpg");
}

.a-allow-title {
  color: #ffffff;
  font-size: 34px;
  font-weight: bold;
}

.a-allow-text {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
}

.a-button-wrapper {
  display: flex;
  justify-content: center;
}