.a-error-title {
  color: #ef6565;
  font-size: 34px;
  font-weight: bold;
  text-transform: capitalize;
}

.a-error-text {
  color: #ffffff;
  font-size: 20px;
  font-weight: 500;
}

.a-button-wrapper {
  display: flex;
  justify-content: center;
}