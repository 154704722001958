.a-item-info-premium {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    font-size: 16px;
  }

  &__top-part {
    padding: 15px 35px 5px 0;
    font-size: 40px;
    line-height: 1;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding: 5px 8px 5px 0;
      font-size: 28px;
    }
  }

  &__bottom-part {
    padding: 15px;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding: 5px 8px 5px 0;
      font-size: 12px;
    }
  }

  &__bottom-part-top {
    font-size: 20px;
    margin-bottom: 0;
  }
  &__bottom-part-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__title {
    font-size: 20px;
    margin-right: 15px;
  }

  &__image {
    display: flex;
    align-items: center;
    width: auto;
    height: 25px;
    margin: 10px 5px;

    @media only screen and (max-width: 1024px) {
      width: auto;
      height: 20px;
      margin: 10px 5px;
    }

    img {
      display: block;
      width: auto;
      height: 25px;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .a-item-info {

    &__top-part {
      padding: 10px 15px;
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize;
    }

    &__bottom-part {
      padding: 10px 15px;
      font-size: 15px;
    }
  }
}