.o-overlay-result {
  width: 100%;
  min-height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 2;

  @media only screen and (max-width: 820px) {
    bottom: 20px;
  }

  &.hide {
    display: none !important;
  }
}