.m-item-result-premium {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    padding: 15px;
    background-color: darkblue;
    position: relative;
    border-radius: 7px;
    z-index: 2;
    &__dynamic-background-image {
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        z-index: -1;
        overflow: hidden;
        &__wrapper-image {
            height: inherit;
            width: inherit;
            position: relative;
            img {
                position: absolute;
            }
        }
    }
    @media only screen and (max-width: 812px) {
        position: relative;
    }
    @media only screen and (max-width: 480px) {
        position: relative;
    }

    &__wrapper {
        display: flex;
        width: 100%;
    }

    &__logo-container {
        display: flex;
        justify-content: center;
        width: 20%;
    }

    &__info-container {
        font-size: 28px;
        padding: 0 0 0 35px;
        width: 80%;

        @media only screen and (max-width: 812px) {
            font-size: 20px;
            padding: 0 0 0 8px;
            width: 80%;
        }
        z-index: 1;
    }

    &__wrapper-image {
        display: flex;
        align-items: flex-end;
        width: 140px;
        height: auto;

        @media only screen and (max-width: 812px) {
            width: 80%;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
            margin: auto;
        }
    }
}
