.sdk-wrapper {
  position: relative;
  max-width: 1280px;
  height: 100%;
  min-height: 100vh;
  margin: auto;
  @media only screen and (max-width: 820px) {  
    min-height: 80vh;
  }
  @media only screen and (max-width: 480px)  {
    min-height: 70vh;
  }
}

.image {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
}

.button-wrapper {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 11;
}

.button-screenshot {
  border: none;
  border-radius: 2px;
  margin: 15px;
  padding: 12px 18px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  background-color: #2196f3;
  box-shadow: 0 0 4px #999;
  outline: none;
  background-position: center;
  transition: background 0.8s;

  &:hover {
    background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
  }
  &:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
}

.output-wrapper {
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  z-index: 4;
  margin: auto;
  opacity: 0;
}

.output {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 70px;
  color: white;

  @media only screen and (max-width: 480px) {
    font-size: 50px;
  }
}

.sdk-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1280px;
  max-height: 100%;
}

.scandit-video {
  height: 85%;

  @media only screen and (max-width: 480px) {
    width: 100%;
    height: auto;
    margin: auto;
  }
}

.sdk-screen-img {
  max-width: 1280px;
  margin: auto;
}

.sdk-screen-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.select-wrapper {
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 11;
}