.a-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 170px;
  font-size: 18px;
  text-transform: capitalize;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
  margin-right: 14px;

  @media only screen and (max-width: 812px) {
    padding: 2px 8px 2px;
    font-size: 12px;
    margin-right: 0;
  }
}
.a-wrapper-image {
  width: 30px;
  max-width: 50px;
  height: auto;
  margin-left: 10px;

  @media only screen and (max-width: 812px) {
    width: 25px;
    max-width: 40px;
    margin-left: 8px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: auto;
  }
}

@media only screen and (max-width: 480px) {
  .a-item-name {
    font-size: 15px;
    padding: 8px;
  }
}