.m-item-result {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
    max-width: 420px;

    @media only screen and (max-width: 812px) {
        position: relative;
        max-width: 60%;
    }
    @media only screen and (max-width: 480px) {
        position: relative;
        max-width: 85%;
    }
}
  