.a-item-info {
  width: 100%;
  border-radius: 18px;
  color: white;
  overflow: hidden;

  @media only screen and (max-width: 812px) {
    font-size: 16px;
    border-radius: 10px 0 10px 10px;
  }

  &__top-part {
    padding: 25px 35px;
    font-size: 30px;

    @media only screen and (max-width: 812px) {
      padding: 5px 8px;
      font-size: 16px;
    }
  }

  &__bottom-part {
    padding: 25px 35px;
    border-radius: 0 0 18px 18px;

    @media only screen and (max-width: 812px) {
      padding: 5px 8px;
      font-size: 12px;
    }
  }

  &__bottom-part-top {
    font-size: 20px;
    margin-bottom: 10px;
  }
  &__bottom-part-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__title {
    font-size: 20px;
    margin-right: 15px;
  }

  &__image {
    display: flex;
    align-items: center;
    width: auto;
    height: 25px;
    margin: 10px 5px;

    @media only screen and (max-width: 812px) {
      width: auto;
      height: 20px;
      margin: 10px 5px;
    }

    img {
      display: block;
      width: auto;
      height: 25px;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 480px) {
  .a-item-info {

    &__top-part {
      padding: 10px 15px;
      font-size: 20px;
      font-weight: 600;
      text-transform: capitalize;
    }

    &__bottom-part {
      padding: 10px 15px;
      font-size: 15px;
    }
  }
}