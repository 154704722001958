.o-overlay-result-premium {
  width: 100%;
  max-width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  z-index: 2;
  border-radius: 7px;


  &.hide {
    display: none !important;
  }
}